<template>
  <div>
    <main class="page-content">
      <!-- iterating -->
      <div
        v-for="(elem, index) in classesList"
        :key="index"
        class="card"
        :style="setBackground(elem.cover_img_url)"
        @click="toRouteWithParams(elem)"
      >
        <div class="tags tagPos">
          <a>{{ elem.cover_tag[showLang] }}</a>
        </div>

        <h2 class="title">
          {{ helper.displayI18nText($i18n.locale, elem.title) }}
        </h2>

        <div class="content">
          <div class="copy">
            <p>{{ helper.displayI18nText($i18n.locale, elem.summary) }}</p>
            <p>Ivy-Way | {{ $t("classesandcounseling.summer") }}</p>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { helper } from "@ivy-way/material";

export default {
  data() {
    return {
      classesList: [],
      showLang: "en"
    };
  },
  computed: {
    helper() {
      return helper;
    },
    ...mapState("user", ["token", "lang"]),
    ...mapGetters("classesandcounseling", ["getCourses"], ["lang"])
  },
  watch: {
    getCourses(newVal) {
      this.classesList = newVal.courses.filter(({ show_index }) => show_index);
    },
    lang(val) {
      if (val.value == "ZH-CN") {
        this.showLang = "cn";
      } else if (val.value == "ZH-TW") {
        this.showLang = "tw";
      } else if (val.value == "EN-US") {
        this.showLang = "en";
      }
    }
  },
  async mounted() {
    const payload = { is_visible: 1, show_index: 1 };
    await this.$store.dispatch("classesandcounseling/getCourses", payload);
  },
  methods: {
    toRouteWithParams(classData) {
      if (classData.page_url) {
        this.$router.push({
          name: classData.page_url,
          params: {
            classData
          }
        });
      } else {
        this.$router.push({
          name: "ClassTemplate",
          params: {
            courseId: classData.id
          }
        });
      }
    },
    setBackground(bgUrl) {
      return {
        backgroundImage: "url(" + bgUrl + ")"
      };
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/tags.scss";
</style>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Cardo:400i|Rubik:400,700&display=swap");
@import "@/assets/scss/tags.scss";

$bp-md: 750px;
$bp-lg: 1100px;

:root {
  --d: 700ms;
  --e: cubic-bezier(0.19, 1, 0.22, 1);
  --font-sans: "Rubik", sans-serif;
  --font-serif: "Cardo", serif;
}

.page-content {
  display: grid;
  grid-gap: 3rem;
  padding: 1rem;
  margin: 3rem 3rem;
  overflow-y: hidden;
  overflow-x: hidden;
  font-family: var(--font-sans);

  @media (max-width: 450px) {
    margin: 2rem 1rem;
    grid-gap: 3rem;
  }

  @media (min-width: $bp-md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: $bp-lg) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.card {
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: 0;
  width: 100%;
  height: 270px;
  text-align: center;
  cursor: pointer;
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
    0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1),
    0 16px 16px rgba(0, 0, 0, 0.1);

  // @media (max-width: $bp-md) {
  //   height: 300px;
  // }

  &:after {
    content: "";
    position: relative;
    top: 135px;
    left: 0;
    width: 100%;
    height: 200px;
    pointer-events: none;
    transform: translateY(-50%);
    transition: transform calc(var(--d) * 2) var(--e);
  }
}

// 調整 hover 後顯示內容區塊
.content {
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  height: 0px;
  width: 100%;
  padding: 0 2rem;
  margin: 0px;
  transition: transform 1s cubic-bezier(0.3, 1, 0.4, 1);
  z-index: 1;
  transform: translateY(220px);

  > * + * {
    margin-top: 1rem;
  }
  > *:not(.title) {
    opacity: 0;
    transform: translateY(1rem);
    transition: transform var(--d) var(--e), opacity var(--d) var(--e);
  }
}

// 調整標題
.title {
  position: absolute;
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.2;
  color: rgba(33, 33, 33, 1);
  background: rgba(255, 255, 255, 0.9);
  padding: 10px;
  margin: 0px;
  width: 100%;
}

// 調整右下角 tag
.tagPos {
  z-index: 1000;
  position: relative;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.2;
  margin: 210px -15px 30px 0px;

  a {
    color: #fff !important;
  }
}

// 課程介紹區塊
.copy {
  position: relative;
  top: 0px;
  font-family: var(--font-serif);
  font-size: 1.125rem;
  font-style: italic;
  line-height: 1.35;
  height: 0px;
  padding: 0;
  margin: 0;
}

// 小於 450 則 tag 位置往下調整
@media (max-width: 450px) {
  .content .copy {
    font-size: 0.9rem;
  }
}

.card:after {
  transform: translateY(0);
}

// @media (min-width: 970px) {
//   .copy {
//     padding: 2px 25px 0px 25px;
//   }
// }

// @media (min-width: 751px) {
//   .copy {
//     padding: 2px 25px 0px 25px;
//   }
// }

// 微調 response 顯示
// @media (max-width: $bp-md) {
//   .copy {
//     padding: 5px 15px 0px 15px;
//     height: 80px;
//   }
// }

// @media (max-width: 500px) {
//   .copy {
//     padding: 3px 3px 0px 3px;
//     height: 90px;
//   }
// }

.card:hover,
.card:focus-within {
  align-items: center;
  height: 270px;

  &:before {
    transform: translateY(-4%);
    height: 270px;
  }
  &:after {
    transform: translateY(-50%);
    height: 270px;
    // hover 後增加漸層黑色透明背景
    background-image: linear-gradient(
      to bottom,
      hsla(0, 0%, 0%, 0) 0%,
      hsla(0, 0%, 0%, 0.5) 22.1%,
      hsla(0, 0%, 0%, 0.5) 39.4%,
      hsla(0, 0%, 0%, 0.5) 53.1%,
      hsla(0, 0%, 0%, 0.5) 64.3%,
      hsla(0, 0%, 0%, 0.6) 74.1%,
      hsla(0, 0%, 0%, 0.7) 83.6%,
      hsla(0, 0%, 0%, 0.8) 94.1%,
      hsla(0, 0%, 0%, 0.9) 100%
    );
  }

  .content {
    transform: translateY(100px);
    height: 170px;

    > *:not(.title) {
      opacity: 1;
      transform: translateY(0);
      transition-delay: calc(var(--d) / 8);
    }
  }
}

.card:focus-within {
  &:before,
  &:after,
  .content,
  .content > *:not(.title) {
    transition-duration: 0s;
  }
}

// hover 後不顯示 tag
.card:hover .tagPos {
  display: none;
}
</style>
